import React from 'react';

import Layout from '../components/Layout';
import Section from '../components/Section';
import LegalTextblock from '../components/LegalTextblock';

const PrivacyPage = () => (
    <Layout title="Datenschutz">
        <Section bg="gray" container="l">
            {/* eslint-disable react/jsx-one-expression-per-line */}
            <LegalTextblock>
                <div>
                    <h1>Datenschutz</h1>
                    <p>
                        Der Schutz Ihrer Privatsphäre ist uns wichtig, weshalb wir Sie darüber
                        informieren möchten, welche Maßnahmen und Grundsätze bei der
                        Datenverarbeitung dieser Website angewendet werden.
                    </p>
                    <p>
                        Unterschieden wird hierbei zwischen Zugriffsdaten und personenbezogenen
                        Daten. Zugriffsdaten werden bei der allgemeinen Nutzung unserer Website
                        automatisch erhoben und gespeichert, wohingegen personenbezogene Daten nur
                        auf freiwilliger Basis und mit Ihrer Erlaubnis gespeichert und verarbeitet
                        werden.
                    </p>
                    <p>
                        Mit der Zustimmung zu der nachfolgenden Datenschutzerklärung willigen Sie
                        gegenüber <strong> Gottwald Elektrotechnik </strong>
                        in die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten
                        unter Beachtung der Datenschutzgesetze und den nachfolgenden Bestimmungen
                        ein.
                    </p>
                </div>
                <div>
                    <h2>1. Veranwortliche Stelle</h2>
                    <p>
                        Falls Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch{' '}
                        <strong>Gottwald Elektrotechnik </strong>
                        entsprechend der nachfolgenden Datenschutzbestimmungen insgesamt oder für
                        einzelne Maßnahmen widersprechen wollen, richten Sie Ihren Widerspruch bitte
                        per E-Mail, Fax oder Brief an:
                    </p>
                    <p>
                        <strong>Gottwald Elektrotechnik</strong>
                        <br />
                        Durlanger Str. 24
                        <br />
                        73568 Durlangen- Zimmerbach
                    </p>
                </div>
                <div>
                    <h2>
                        2. Zwecke der Datenverarbeitung, Rechtsgrundlage sowie berechtigte
                        Interessen des für die Verarbeitung Verantwortlichen und Dauer der
                        Speicherung
                    </h2>
                    <h3>2.1 Allgemeine Hinweise zur Datenverarbeitung</h3>
                    <p>
                        Die Nutzung unserer Internetseiten ist in der Regel ohne Angabe
                        personenbezogener Daten möglich. Wir erheben Ihre personenbezogenen Daten
                        nur, soweit dies zur Bereitstellung unseres Internetangebots sowie unserer
                        Leistungen erforderlich ist. Soweit Sie auf unseren Seiten personenbezogene
                        Daten (beispielsweise Name, Anschrift, E-Mail-Adresse oder Telefonnummer)
                        angeben, erfolgt dies grundsätzlich auf freiwilliger Basis. Ohne Ihre
                        Einwilligung verarbeiten wir Ihre Daten grundsätzlich nur, soweit uns eine
                        vorherige Einholung Ihrer Einwilligung aus tatsächlichen Gründen nicht
                        möglich und die Verarbeitung der Daten durch gesetzliche Vorschriften
                        ausdrücklich gestattet ist.
                    </p>
                    <p>
                        <strong>
                            Rechtsgrundlagen der Verarbeitung Ihrer personenbezogenen Daten sind:
                        </strong>
                    </p>
                    <ul>
                        <li>
                            Art. 6 Abs. 1 lit. a) DSGVO, soweit Sie ausdrücklich in die Verarbeitung
                            Ihrer Daten zu einem bestimmten Zweck eingewilligt haben,
                        </li>
                        <li>
                            Art. 6 Abs. 1 lit. b) DSGVO, soweit die Verarbeitung Ihrer Daten zur
                            Erfüllung eines Vertrages, dessen Vertragspartei Sie sind, erforderlich
                            ist sowie zur Durchführung vorvertraglicher Maßnahmen,
                        </li>
                        <li>
                            Art. 6 Abs. 1 lit. c) DSGVO, soweit die Erfüllung Ihrer Daten zur
                            Erfüllung rechtlicher Verpflichtungen erforderlich ist sowie
                        </li>
                        <li>
                            Art. 6 Abs. 1 lit. f) DSGVO, soweit die Verarbeitung zur Wahrung eines
                            berechtigten Interesses unseres Unternehmens oder eines Dritten
                            erforderlich ist und entgegenstehende Interessen und Grundrechte der
                            betroffenen Person nicht überwiegen.
                        </li>
                    </ul>
                    <p>
                        Personenbezogene Daten werden gelöscht oder in ihrer Verarbeitung
                        eingeschränkt, sobald der Zweck der Verarbeitung entfällt, soweit nicht
                        gesetzliche, vertragliche oder satzungsmäßige Vorgaben die Aufbewahrung der
                        Daten erfordern.
                    </p>
                    <p>
                        <strong>
                            In folgenden Fällen erfolgt eine Verarbeitung personenbezogener Daten:
                        </strong>
                    </p>
                    <h3>2.2 Aufrufen unserer Internetseiten</h3>
                    <p>
                        Systembedingt werden beim Aufrufen unserer Internetseiten Informationen in
                        sogenannten Server-Log-Files erhoben und gespeichert, die Ihr Browser
                        automatisch an uns übermittelt. Dies sind:
                    </p>
                    <ul>
                        <li>Anonymisierte IP-Adresse des abrufenden Endgeräts,</li>
                        <li>Datum und Uhrzeit des Zugriffs,</li>
                        <li>Name und URL der abgerufenen Datei,</li>
                        <li>
                            Referrer-URL (Internetseite, von der aus der Zugriff erfolgte) und
                            aufgerufene Links,
                        </li>
                        <li>
                            Information über den Browsertyp, die verwendete Version und ggf. das von
                            Ihnen verwendete Betriebssystem sowie der Name Ihres Access-Providers.
                        </li>
                    </ul>
                    <p>
                        Diese Daten sind für uns nicht bestimmten Personen zuordenbar, Rückschlüsse
                        auf Ihre Identität sind uns nicht möglich. Die Daten der Logfiles werden
                        stets getrennt von anderen personenbezogenen Daten gespeichert; eine
                        Zusammenführung mit anderen Datenquellen erfolgt nicht.
                    </p>
                    <p>
                        Diese Daten werden auf der Grundlage von Art. 6 Abs. 1 Buchstabe f) DSGVO
                        verarbeitet zur Auslieferung der Inhalte unseres Internetauftrittes sowie
                        zur Gewährleistung der Funktionsfähigkeit unserer informationstechnischen
                        Systeme, zur Optimierung unserer Internetseiten sowie zur Auswertung der
                        Systemsicherheit und -stabilität.
                    </p>
                    <p>
                        Die Daten werden für einen Zeitraum von maximal 30 Tagen gespeichert und
                        anschließend automatisch gelöscht.
                    </p>
                    <p>
                        Eine Löschung Ihrer personenbezogenen Daten erfolgt, sofern gesetzliche
                        Aufbewahrungspflichten dem nicht entgegenstehen und wenn Sie einen
                        Löschungsanspruch geltend gemacht haben, wenn die Daten zur Erfüllung des
                        mit der Speicherung verfolgten Zwecks nicht mehr erforderlich sind oder wenn
                        ihre Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist.
                    </p>
                </div>
                <div>
                    <h2>3. Cookies</h2>
                    <h3>3.1 Allgemeine Hinweise</h3>
                    <p>
                        Unsere Internetseiten verwenden teilweise sogenannte Cookies. Der Einsatz
                        von Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f) DSGVO zum Zweck
                        der Optimierung unserer Internetseiten sowie zur Auswertung von
                        Nutzerzugriffen („berechtigtes Interesse“).
                    </p>
                    <p>
                        Cookies sind kleine Textdateien, die auf Ihrem Endgerät abgelegt werden und
                        die Ihr Browser speichert. Cookies richten keinen Schaden an und enthalten
                        keine Viren. Sie dienen uns dazu, unser Angebot nutzerfreundlicher,
                        effektiver und sicherer zu machen. Die meisten der von uns verwendeten
                        Cookies sind „transistente Cookies“, insb. sogenannte „Session-Cookies“. Sie
                        werden nach Ende Ihres Besuchs automatisch gelöscht und dienen uns z.B. zur
                        Erkennung, ob Sie einzelne Seiten unseres Internetauftritts bereits besucht
                        haben. Andere Cookies verbleiben für einen bestimmten Zeitraum auf Ihrem
                        Endgerät („persistente Cookies“) und ermöglichen uns, Ihren Browser beim
                        nächsten Besuch wiederzuerkennen. Zudem setzen wir Cookies ein, um die
                        Nutzung unserer Internetseiten statistisch zu erfassen und zum Zwecke der
                        Optimierung unseres Internetangebotes auszuwerten. Die Speicherdauer ist
                        abhängig vom Verwendungszweck des jeweiligen Cookies. Sie selbst können
                        Cookies über die Einstellungen in Ihrem Browser jederzeit löschen
                    </p>
                    <p>
                        Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren
                        Browser aber auch so einstellen, dass Sie über das Setzen von Cookies
                        informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von
                        Cookies für bestimmte Fälle oder generell ausschließen sowie das
                        automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei
                        der Deaktivierung von Cookies kann die Funktionalität unserer Internetseiten
                        eingeschränkt sein.
                    </p>
                    <p>Wir unterscheiden zwischen „technischen Cookies“ und „Tracking Cookies“.</p>
                    <h3>3.2 Technische Cookies</h3>
                    <p>
                        „Technische Cookies“ werden von uns auf der Grundlage von Art. 6 Abs. 1 lit.
                        f) DSGVO zur Auslieferung sowie zur Optimierung unseres Internetangebots
                        genutzt. Sie dienen uns dazu, unser Angebot nutzerfreundlicher, effektiver
                        und sicherer zu machen. Nur mithilfe von technischen Cookies (insb.
                        Navigations- und Session Cookies), können wir eine reibungsfreie Navigation
                        und Nutzung unserer Internetseiten gewährleisten sowie Ihnen weitere
                        Serviceleistungen (z.B. Nutzung unserer zugangsbeschränkten Bereiche)
                        anbieten. Zudem können wir mit diesen Cookies die Performance unserer
                        Internetseiten überwachen, z.B. um Angaben über die Anzahl der besuchten
                        Seiten oder der Nutzer zu erhalten, die einen gewissen Bereich besucht
                        haben. Hierbei werden ausschließlich statistische (anonyme) Daten erstellt,
                        die keinerlei Aufschluss über die Identität der Nutzer unserer
                        Internetseiten zulassen. Diese Daten dienen uns zur Optimierung unserer
                        Internetseiten.
                    </p>
                    <h3>3.3 Tracking Cookies</h3>
                    <p>
                        „Tracking Cookies“ werden auf unseren Internetseiten zur Erstellung von
                        Nutzerprofilen verwendet, damit wir dem Nutzer auf seine Interessen
                        abgestimmte Online-Inhalte präsentieren können. Der Nutzer wird hierbei beim
                        ersten Aufruf unserer Websites und nach 90 Tagen erneut darüber und die
                        Möglichkeit eines Opt-Outs informiert.
                    </p>
                </div>
                <div>
                    <h2>4. Webstatistik</h2>
                    <h3>4.1 GoogleAnalytics</h3>
                    <p>
                        Unsere Internetseiten benutzen auf der Grundlage von Art. 6 Abs. 1 lit. f)
                        DSGVO GoogleAnalytics, einen Webanalysedienst der Google Inc., 600
                        Amphitheatre Parkway Mountain View, Ca 94043, USA („Google“) zum Zwecke der
                        bedarfsgerechten Gestaltung und Optimierung unseres Internetauftrittes.
                    </p>
                    <p>
                        GoogleAnalytics verwendet Cookies und erstellt pseudonymisierte
                        Nutzungsprofile. Die durch den Cookie erzeugten Informationen über Ihre
                        Benutzung unserer Internetseiten (Browsertyp und -version; Betriebssystem,
                        Referrer-URL, IP-Adresse und Uhrzeit der Serveranfrage) werden in der Regel
                        an einen Server von Google in den USA übertragen und dort gespeichert. Ihre
                        IP-Adresse wird von Google zuvor innerhalb der Mitgliedsstaaten der
                        Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                        Europäischen Wirtschaftsraum gekürzt und so anonymisiert, dass eine
                        Zuordnung zu Ihrer Person nicht möglich ist (IP-Masking). Eine
                        Zusammenführung der IP-Adresse mit anderen Daten von Google erfolgt nicht.
                        In unserem Auftrag gemäß Art. 28 DSGVO wird Google die erhobenen
                        Informationen verwenden, um Ihre Nutzung unserer Internetseiten auszuwerten,
                        um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit
                        der Nutzung unserer Internetseiten und des Internets verbundene
                        Dienstleistungen zu erbringen.
                    </p>
                    <p>
                        Sie können die Speicherung von Cookies durch eine entsprechende Einstellung
                        Ihrer Browser-Software verhindern; wir weisen jedoch darauf hin, dass Sie in
                        diesem Fall gegebenenfalls nicht sämtliche Funktionen unseres
                        Internetauftritts vollumfänglich werden nutzen können. Sie können darüber
                        hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung
                        unserer Internetseiten bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
                        sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das
                        unter dem folgenden Link verfügbare Browser-Add-on herunterladen und
                        installieren:
                    </p>
                    <p>
                        <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
                            http://tools.google.com/dlpage/gaoptout?hl=de
                        </a>
                    </p>
                    <p>
                        Alternativ zum Browser-Add-On können Sie die Erfassung durch GoogleAnalytics
                        zudem verhindern, indem Sie folgenden Link klicken.
                    </p>
                    <p>
                        {/* eslint-disable */}
                        <a
                            onClick={evt => {
                                evt.preventDefault();
                                alert('Google Analytics deaktiviert.');
                                window['ga-disable-UA-158908117-1'] = true;
                            }}
                        >
                            GoogleAnalytics deaktivieren
                        </a>
                        {/* eslint-enable */}
                    </p>
                    <p>
                        Dabei wird ein Opt-Out-Cookie auf Ihrem Endgerät abgelegt, der die künftige
                        Erfassung Ihrer Daten beim Besuch unserer Internetseiten verhindert. Bitte
                        beachten Sie, dass das Opt-Out-Cookie nur in diesem Browser und nur für
                        unsere Internetseite gilt und dass Sie das Cookie erneut setzen müssen,
                        sollten Sie die Cookies in Ihrem Browser löschen.
                    </p>
                    <p>
                        Unsere Internetseiten verwenden GoogleAnalytics zudem für eine
                        geräteübergreifende Analyse von Besucherströmen, die über eine User-ID
                        durchgeführt wird. Um die Erfassung durch Universal Analytics über
                        verschiedene Geräte hinweg zu verhindern, müssen Sie das Opt-Out auf allen
                        genutzten Systemen durchführen.
                    </p>
                    <p>
                        Weitere Informationen zu Nutzungsbedingungen und Datenschutz im Zusammenhang
                        mit GoogleAnalytics finden Sie auf den
                        <br />
                        <a
                            href="http://www.google.com/intl/de/analytics/privacyoverview.html"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Internetseiten von Google.
                        </a>
                    </p>
                </div>
                <div>
                    <h2>5. Weitere Empfänger Ihrer Daten</h2>
                    <p>
                        Eine Weitergabe Ihrer Daten an Dritte erfolgt – soweit unter Ziff. 2 nicht
                        ausdrücklich erläutert – grundsätzlich nicht. Insbesondere geben wir Ihre
                        Daten mit Ausnahme der unter Ziff. 2 dargestellten Verarbeitungen nicht an
                        Empfänger mit Sitz außerhalb der Europäischen Union oder des Europäischen
                        Wirtschaftsraums weiter.
                    </p>
                    <p>
                        Teilweise bedienen wir uns zur Verarbeitung personenbezogener Daten externer
                        Dienstleister im Rahmen einer Auftragsverarbeitung nach Art. 28 DSGVO (z.B.
                        IT-Dienstleistungen, Aktenvernichtung, Druckdienstleistungen, etc.). Diese
                        Dienstleister wurden von uns sorgfältig ausgewählt und beauftragt, sind an
                        unsere Weisungen gebunden und werden regelmäßig kontrolliert.
                    </p>
                    <p>
                        Eine Weitergabe Ihrer Daten z.B. an Aufsichtsbehörden und
                        Strafverfolgungsbehörden erfolgt nur im Rahmen der gesetzlichen
                        Vorschriften, wenn es zur Verhinderung und Aufdeckung von Betrugsfällen und
                        sonstigen Straftaten oder zur Gewährleistung der Sicherheit unserer
                        Datenverarbeitungssysteme erforderlich ist. Rechtsgrundlagen hierfür sind
                        Art. 6 Abs. 1 lit. c) [Erfüllung gesetzlicher Verpflichtungen] und lit. f)
                        DSGVO [„Wahrung berechtigter Interessen“].
                    </p>
                </div>
                <div>
                    <h2>6. Betroffenenrechte</h2>
                    <p>
                        Sofern wir Ihre personenbezogenen Daten verarbeiten, stehen Ihnen die
                        folgenden Betroffenenrechte nach Kapitel 3 DSGVO zu:
                    </p>
                    <h3>6.1 Widerspruchsrecht</h3>
                    <p>
                        Sie können der Verarbeitung Ihrer persönlichen Daten widersprechen, soweit
                        diese aufgrund von Art. 6 Abs. 1 Buchstabe f) DSGVO („berechtigte Zwecke“)
                        gestützt wird (Art. 21 DSGVO). Eine Verpflichtung zur Umsetzung Ihres
                        Widerspruchs besteht nach Art. 21 Abs. 1 Satz 1 DSGVO jedoch nur, wenn Sie
                        uns hierfür Gründe von übergeordneter Bedeutung nennen, die sich aus ihrer
                        besonderen Situation ergeben. Der Verarbeitung Ihrer persönlichen Daten zu
                        Werbe-/Marketingzwecken können Sie jederzeit – ohne Angabe von Gründen –
                        widersprechen
                    </p>
                    <h3>
                        6.2 Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
                    </h3>
                    <p>
                        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligung jederzeit ohne
                        Angabe von Gründen zu widerrufen. Durch den Widerruf der Einwilligung wird
                        die Rechtmäßigkeit der aufgrund der erklärten Einwilligung bis zum Widerruf
                        erfolgten Verarbeitung nicht berührt.
                    </p>
                    <h3>6.3 Weitere Betroffenenrechte</h3>
                    <p>
                        Zudem können Sie – bei Vorliegen der jeweiligen gesetzlichen Voraussetzungen
                        – folgende Rechte in Anspruch nehmen:
                    </p>
                    <ul>
                        <li>
                            Recht auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten,
                            insb. über die Verarbeitungszwecke, die Kategorien der Daten und deren
                            Herkunft, falls die Daten nicht direkt bei Ihnen erhoben wurden, die
                            Kategorien der Empfänger Ihrer Daten, die geplante Speicherdauer sowie
                            Ihre Betroffenenrechte (Art. 15 DSGVO),
                        </li>
                        <li>
                            Recht auf Berichtigung unrichtiger oder unvollständiger
                            personenbezogener Daten (Art. 16 DSGVO),
                        </li>
                        <li>
                            Recht auf Löschung Ihrer persönlichen Daten, insb. wenn die Daten für
                            die Zwecke, für die sie erhoben wurden, nicht mehr notwendig sind und
                            wir nicht aufgrund gesetzlicher, satzungsmäßiger oder vertraglicher
                            Vorgaben zur Aufbewahrung der Daten verpflichtet sind (Art. 17 DSGVO),
                        </li>
                        <li>
                            Recht auf Einschränkung der Verarbeitung Ihrer persönlichen Daten (Art.
                            18 DSGVO),
                        </li>
                        <li>
                            Recht auf Datenübertragbarkeit hinsichtlich der uns von Ihnen
                            bereitgestellten persönlichen Daten in einem gängigen, maschinenlesbaren
                            Format (Art. 20 DSGVO).
                        </li>
                    </ul>
                    <h3>6.4 Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
                    <p>
                        Nach Art. 77 DSGVO haben Sie unbeschadet anderweitiger
                        verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe das Recht auf
                        Beschwerde bei einer Aufsichtsbehörde, wenn sie der Ansicht sind, dass die
                        Verarbeitung der Sie betreffenden Daten durch uns gegen die Vorgaben der
                        DSGVO verstößt.
                    </p>
                </div>
                <div>
                    <h2>7. Datensicherheit</h2>
                    <p>
                        Wir treffen angemessene technische Sicherheitsmaßnahmen, um die uns von
                        Ihnen anvertrauten Daten vor Verlust, Zerstörung, Offenlegung und Zugriff
                        durch Unbefugte zu schützen und passen diese stets den technischen
                        Weiterentwicklungen an. So setzen wir zum Schutz Ihrer Daten, insbesondere
                        bei der Kommunikation über unsere Internetseiten eine SSL-Verschlüsselung
                        ein (
                        <a
                            href="https://letsencrypt.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Lets Encrypt
                        </a>
                        ).
                    </p>
                    <p>
                        Wir weisen allerdings darauf hin, dass die Datenübertragung im Internet
                        (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
                        Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                        möglich.
                    </p>
                </div>
                <div>
                    <h2>8. Links zu externen Websites</h2>
                    <p>
                        Unsere Website enthält Links zu externen Websites. Wir haben jedoch keinen
                        Einfluss auf die Einhaltung der Datenschutzbestimmungen seitens deren
                        Betreiber. Als Anbieter sind wir für eigene Inhalte nach den allgemeinen
                        Gesetzen verantwortlich. Zwischen unseren Inhalten und von anderen Anbietern
                        bereitgestellten Inhalten, auf die von uns verlinkt wird, ist hierbei zu
                        unterscheiden. Für fremde Inhalte, die über entsprechende Links zur Nutzung
                        zugänglich gemacht werden, übernehmen wir keinerlei Verantwortung. Für
                        illegale, fehlerhafte oder unvollständige Inhalte sowie Schäden, die durch
                        die Nutzung oder Nichtnutzung dieser Informationen entstehen, haftet alleine
                        der Anbieter der Website, auf die verwiesen wurde.
                    </p>
                </div>
                <div>
                    <h2>Haben Sie noch Fragen oder Wünsche?</h2>
                    <p>
                        Ihr Vertrauen ist uns wichtig. Daher möchten wir Ihnen jederzeit Rede und
                        Antwort bezüglich der Verarbeitung Ihrer personenbezogenen Daten stehen.
                        Weitere Anregungen zum Thema Datenschutz sind uns ebenfalls sehr wichtig und
                        jederzeit willkommen. Wenn Sie Fragen haben, die Ihnen diese
                        Datenschutzerklärung nicht beantworten konnte oder wenn Sie zu einem Punkt
                        tiefergehende Informationen wünschen, so helfen wir Ihnen gerne weiter.
                        Kontaktieren Sie uns in diesem Fall einfach über die zuvor genannte Adresse.
                    </p>
                </div>
            </LegalTextblock>
        </Section>
    </Layout>
);

export default PrivacyPage;
